import {
  Text,
  Stack,
  Fade,
  ScaleFade,
  HStack,
  Icon,
  useColorModeValue,
  useToast,
  Tooltip,
  Box,
  Heading,
} from "@chakra-ui/react";
import Link from "next/link";
import { TemplateProps } from "../types/types";
import * as Icons from "react-icons/fc";
import { useEffect, useMemo, useState } from "react";
import { FcLike, FcLikePlaceholder } from "react-icons/fc";
import { trpc } from "../utils/trpc";
import { useSession } from "next-auth/react";
import loveAnimation from "../public/animations/color-love.json";
import Lottie from "react-lottie";

const TemplateCard: React.FC<{
  template: TemplateProps;
}> = ({ template }) => {
  const { data } = useSession();
  const toast = useToast();
  const toastId = "like-toast";
  const favoriteMutation = trpc.useMutation(["add-favorite"]);
  const [isLiked, setIsLiked] = useState(false);
  const [isLiking, setIsLiking] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: loveAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const activeIcon = useMemo(
    () => Icons[template.icon as unknown as keyof typeof Icons],
    [template]
  );

  useEffect(() => {
    if (favoriteMutation.isSuccess && !toast.isActive(toastId)) {
      // invalidateQueries();
      toast({
        id: toastId,
        title: "Saved",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [favoriteMutation.isSuccess, toast, toastId]);

  useEffect(() => {
    if (data && data.user) {
      const { user } = data;
      const { Favorites } = template;
      const isLiked = Favorites.some(
        (favorite) => favorite.email === user.email
      );
      setIsLiked(isLiked);
    }
  }, [template, data]);

  const handleFavorite = async () => {
    setIsLiking(false);
    handleFavoriteAnimation();
    setIsLiked(!isLiked);
    await favoriteMutation.mutateAsync({
      templateId: template.id as unknown as string,
    });
  };

  const handleFavoriteAnimation = () => {
    if (!isLiked) {
      setIsLiking(true);
    }
  };

  return (
    <ScaleFade initialScale={0.7} in={true} unmountOnExit>
      <Stack
        p="4"
        m="4"
        borderRadius={12}
        boxShadow="md"
        bg={useColorModeValue("white", "gray.700")}
      >
        <Stack gap={2}>
          <HStack gap={2} justifyContent="space-between">
            <HStack>
              {template.icon && (
                <Link href={`/templates/${template.id}`}>
                  <Icon
                    as={activeIcon}
                    fontSize={["20px", "30px"]}
                    cursor="pointer"
                  />
                </Link>
              )}
              <Tooltip label={template.name} openDelay={500}>
                <Link href={`/templates/${template.id}`}>
                  <Stack direction="row" alignItems="center" cursor="pointer">
                    <Heading
                      as="h3"
                      fontSize="lg"
                      noOfLines={1}
                      fontWeight="semibold"
                    >
                      {template.name}
                    </Heading>
                  </Stack>
                </Link>
              </Tooltip>
            </HStack>
            <Box zIndex={1} cursor="pointer">
              {isLiked && (
                <Box onClick={handleFavorite}>
                  {!isLiking && isLiked && <Icon width={34} as={FcLike} />}
                  {isLiking && isLiked && (
                    <Lottie
                      options={defaultOptions}
                      isStopped={!isLiking}
                      width={32}
                      height={30}
                      eventListeners={[
                        {
                          eventName: "complete",
                          callback: () => {
                            setIsLiking(false);
                          },
                        },
                      ]}
                    />
                  )}
                </Box>
              )}
              {!isLiked && (
                <Fade in={true} onClick={handleFavorite}>
                  <Icon width={34} as={FcLikePlaceholder} />
                </Fade>
              )}
            </Box>
          </HStack>
          <Link href={`/templates/${template.id}`}>
            <Stack
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
              cursor="pointer"
            >
              <Text
                noOfLines={2}
                fontSize={{ base: "sm" }}
                textAlign={"left"}
                maxW={"4xl"}
              >
                {template.description}
              </Text>
            </Stack>
          </Link>
        </Stack>
      </Stack>
    </ScaleFade>
  );
};

export default TemplateCard;
