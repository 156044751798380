import {
  Box,
  SimpleGrid,
  Flex,
  Tag,
  Skeleton,
  Stack,
  Heading,
  Center,
  HStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import dynamic from "next/dynamic";
import { TemplateProps, TemplateTag } from "../types/types";
import TemplateCard from "./TemplateCard";
import { trpc } from "../utils/trpc";
import LoadingCard from "./LoadingCard";

// const Tour = dynamic(() => import("reactour"), { ssr: false });
// const steps = [
//   {
//     selector: ".first-step",
//     content:
//       "Lets create a blog together. Lets use the blog topic idea as a starting point.",
//     style: {
//       background: "white",
//       color: "black",
//     },
//   },
// ];

// make a TabGridProps type that accepts templates of type TemplatesProps
const TabGrid: React.FC = () => {
  const [activeFilter, setActiveFilter] = useState<TemplateTag>(
    TemplateTag.All
  );
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [templates, setTemplates] = useState<TemplateProps[]>([]);
  const templatesQuery = trpc.useQuery([
    "get-templates",
    { filter: activeFilter },
  ]);

  const handleTagChange = (tab: TemplateTag) => {
    console.log(
      "🚀 ~ file: TabGrid.tsx ~ line 45 ~ handleTagChange ~ tab",
      tab
    );
    if (activeFilter === tab) {
      setActiveFilter(TemplateTag.All);
      return;
    }
    setActiveFilter(tab);
  };

  // const closeTour = () => {
  //   setIsTourOpen(false);
  // };

  useEffect(() => {
    if (templatesQuery.data) {
      setTemplates(templatesQuery.data as any);
    }
  }, [templatesQuery]);

  return (
    <Box minHeight="80vh">
      <Box>
        <Flex gap={1} flexWrap="wrap">
          {Object.keys(TemplateTag).map((tag) => (
            <Tag
              onClick={() => handleTagChange(tag as TemplateTag)}
              key={tag}
              cursor="pointer"
              bgColor={activeFilter === tag ? "green.300" : "transparent"}
            >
              {tag}
            </Tag>
          ))}
        </Flex>
        {templatesQuery.isLoading && (
          <SimpleGrid mt={4} gap={4} columns={[1, 1, 2, 3]}>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </SimpleGrid>
        )}
        {templatesQuery.data && (
          <>
            <SimpleGrid columns={[1, 2, 2, 3]}>
              {templates.map((template) => {
                return <TemplateCard key={template.id} template={template} />;
              })}
            </SimpleGrid>
            {!templates.length &&
              activeFilter.toLocaleLowerCase() === TemplateTag.Favorites && (
                <HStack p={4} w="full" justifyContent="center">
                  <Heading as="h2" size="lg" textAlign="center">
                    No favorites found
                  </Heading>
                </HStack>
              )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TabGrid;
