import type { NextPage, NextPageContext } from "next";
import Head from "next/head";

import TabGrid from "../components/TabGrid";
import RoundCard from "../components/RoundCard";
import { MetaProps } from "../types/types";
import Base from "../layouts/Base";
import { trpc } from "../utils/trpc";
import Script from "next/script";
import axios from "axios";
import { getSession } from "next-auth/react";

const Index: NextPage<
  MetaProps & { user: { name: string; email: string; createdAt: string } }
> = ({ title, description, image, user }) => {
  return (
    <Base>
      <Head>
        <title>AvvA ai</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
        <meta
          property="og:image"
          content="https://picsum.photos/id/1/400/400"
        />
      </Head>

      <RoundCard>
        <TabGrid />
      </RoundCard>
      <Script
        id="intercom-loggedin"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}",
    name: "${user.name}", // Full name
    email: "${user.email}", // Email address
    created_at: "${user?.createdAt}" // Signup date as a Unix timestamp
  };`,
        }}
      />
      <Script
        id="intercom-loggedin2"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
        }}
      />
    </Base>
  );
};

export async function getServerSideProps(context: NextPageContext) {
  const session = (await getSession(context)) as { user: { email: string } };
  if (session && session.user && session.user.email) {
    const { data } = await axios.post(
      `${process.env.NEXT_PUBLIC_VERCEL_URL}/api/getUser`,
      {
        email: session.user.email,
      }
    );
    return {
      props: {
        user: data.user,
      },
    };
  }

  return {
    props: {
      user: {
        name: "Anonymous",
        email: "Anonymous",
        createdAt: "Anonymous",
      },
    },
  };
}

export default Index;
