import { HTMLInputTypeAttribute, ReactNode } from "react";
import { TMutation } from "../utils/trpc";
import type { DefaultUser } from "next-auth";
import { PrismaClient } from "@prisma/client";
import { IconType } from "react-icons";
// TypeScript users only add this code
import { BaseEditor, Descendant } from "slate";
import { ReactEditor } from "slate-react";

type CustomElement = { type: "paragraph"; children: CustomText[] };
type CustomText = { text: string };

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

declare module "next-auth" {
  interface Session {
    user?: DefaultUser & {
      id: string;
    };
  }
}

declare global {
  var prisma: PrismaClient;
}

declare global {
  interface Window {
    gtag: any;
  }
}

export type TemplateTags = {
  name: TemplateTags;
  id: string;
  templateId: string | null;
};

export enum TemplateTag {
  All = "all",
  Favorites = "favorites",
  SEO = "SEO",
  Writing = "Writing",
  Blog = "Blog",
  Business = "Business",
  Marketing = "Marketing",
  Sales = "Sales",
  Product = "Product",
  Code = "Code",
  Support = "Support",
  Email = "Email",
}

export type MetaProps = {
  title: string;
  description: string;
  image: string;
};

export type Field = {
  name: string;
  property: string;
  type: HTMLInputTypeAttribute;
  required: boolean;
  placeholder: string;
};

export type TemplateProps = {
  id: string;
  name: string;
  subheader?: string | null;
  Tags: TemplateTags[];
  description: string;
  Fields: Field[];
  Favorites: any[];
  mutationName: Exclude<TMutation, "update-profile">;
  icon?: IconType;
};

export type SeoSuggestionSubmitProps = {
  brandName: string;
  brandType: string;
  targetAudience: string;
  keywords: string;
};

export type ParagraphGeneratorProps = {
  topic: string;
  tone: string;
  keywords: string;
};

export type TemplatesProps = {
  [id: number]: TemplateProps;
};

export type Profile = {
  brandName: string;
  brandType: string;
  brandValues: string;
  targetAudience: string;
  website: string;
  email: string;
  keywords: string;
};

export type PageMetaData = {
  title: string;
  description: string;
  image?: string;
};

export interface MutationProps {
  ctx: {
    session: {
      user: {
        email: string;
      };
    };
  };
}

export interface TemplateHandlerProps {
  userId: string;
}
